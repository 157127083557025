import Frontoffice from '@zupr/web/src/js/frontoffice/components/base'
import Providers from '@zupr/web/src/js/frontoffice/providers'
import Parked from '@zupr/web/src/js/frontoffice/screens/landing'

import { getProps } from '../helpers/props'

export async function getServerSideProps({ req, params, query }) {
    const props = await getProps({ req, params, query })
    return {
        props,
    }
}

const Index = (props) => (
    <Providers {...props}>
        <Frontoffice>
            <Parked />
        </Frontoffice>
    </Providers>
)

export default Index
